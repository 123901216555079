.radio-row {
    margin-top: 10px;
  }
  
  .radio-box {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .radio-box label {
    font-weight: normal;
  }
  
  .radio-box input[type="radio"] {
    margin-right: 5px;
  }
  