@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-container {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;

  }
  .image-container:hover img {
    opacity: 0.5;
  }
  
  .selected-overlay {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.7;
    transition: opacity 0.3s;
  }
  
  .selected-overlay i {
    font-size: 16px;
    color: #19b394;
  }
  
  .selected {
    opacity: 0.5;
  }
  
  .selected .selected-overlay {
    opacity: 1;
  }
  
  .image-container:hover .selected-overlay {
    opacity: 0.5;
  }
  
  .selected:hover .selected-overlay {
    opacity: 1;
  }
  
  .selected-overlay i.fa {
    font-size: 16px;
    color: #008000;
  }
  
/* For mobile */
.date-input {
    width: 50%;
  }
  
  /* For web */
  @media (min-width: 768px) {
    .date-input {
      width: 20%;
    }
  }
  
.radio-row {
    margin-top: 10px;
  }
  
  .radio-box {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .radio-box label {
    font-weight: normal;
  }
  
  .radio-box input[type="radio"] {
    margin-right: 5px;
  }
  
.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .checkbox-container:hover {
    background-color: #f0f0f0;
  }
  
.rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
}

.rating-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid #007bff;
  color: #007bff;
  font-weight: bold;
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;
}

.rating-button:hover {
  background-color: #007bff;
  color: #fff;
}

.rating-container.active .rating-button {
  background-color: #007bff;
  color: #fff;
}
@media screen and (max-width: 576px) {
  .rating-container {
    margin-right: 10px;
  }
}


.badge-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    margin-right: 8px;
    /* background-color: #f5f5f5; */
    border-radius: 4px;
  }
  .ranking-item {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  
  
