/* For mobile */
.date-input {
    width: 50%;
  }
  
  /* For web */
  @media (min-width: 768px) {
    .date-input {
      width: 20%;
    }
  }
  