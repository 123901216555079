.rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
}

.rating-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid #007bff;
  color: #007bff;
  font-weight: bold;
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;
}

.rating-button:hover {
  background-color: #007bff;
  color: #fff;
}

.rating-container.active .rating-button {
  background-color: #007bff;
  color: #fff;
}
@media screen and (max-width: 576px) {
  .rating-container {
    margin-right: 10px;
  }
}

