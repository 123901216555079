.badge-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    margin-right: 8px;
    /* background-color: #f5f5f5; */
    border-radius: 4px;
  }
  .ranking-item {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  
  