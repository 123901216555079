.image-container {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;

  }
  .image-container:hover img {
    opacity: 0.5;
  }
  
  .selected-overlay {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.7;
    transition: opacity 0.3s;
  }
  
  .selected-overlay i {
    font-size: 16px;
    color: #19b394;
  }
  
  .selected {
    opacity: 0.5;
  }
  
  .selected .selected-overlay {
    opacity: 1;
  }
  
  .image-container:hover .selected-overlay {
    opacity: 0.5;
  }
  
  .selected:hover .selected-overlay {
    opacity: 1;
  }
  
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
  
  .selected-overlay i.fa {
    font-size: 16px;
    color: #008000;
  }
  