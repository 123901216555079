.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .checkbox-container:hover {
    background-color: #f0f0f0;
  }
  